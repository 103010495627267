// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bB_k5";
export var caseStudyBackground__lineColor = "bB_kX";
export var caseStudyCta__bgColor = "bB_lX";
export var caseStudyHead__imageWrapper = "bB_kV";
export var caseStudyProjectsSection = "bB_k6";
export var caseStudyQuote__bgDark = "bB_l5";
export var caseStudyQuote__bgLight = "bB_k3";
export var caseStudyQuote__bgRing = "bB_k2";
export var caseStudySolution__ring = "bB_k0";
export var caseStudySolution__ringThree = "bB_l6";
export var caseStudySolution__ringTwo = "bB_k1";
export var caseStudyVideo = "bB_lg";
export var caseStudyVideo__ring = "bB_k8";
export var caseStudy__bgDark = "bB_kT";
export var caseStudy__bgLight = "bB_kS";